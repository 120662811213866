import * as react from "react";
import "./joinpopup.scss";
import { CircularProgress } from "@mui/material";

import { IRace, TokenInfo, TokenType } from "../../../types";
import { getTokenImage } from "../../../services/queries";
import { UseMutationResult } from "@tanstack/react-query";
import LogoWShadow from "../../assets/logowshadow.png";
import { trimBloodlineAttribute } from "../kennel";
import { useAppSelector } from "../../../app/hooks";
import { selectBalances } from "../../../store/accountSlice";
import { Link } from "react-router-dom";
import { selectAllTokens } from "./../../../store/mainSlice";
import { formatTokenName, token2DecimalDisplay } from "../../../tools/helper";

interface IProps {
  handleJoin: (tokenFamily: string, tokenId: number) => void;
  handleClose: () => void;
  handleReset: () => void;
  raceData: IRace;
  joinStatus: UseMutationResult;
  children?: react.ReactNode;
  error?: string;
}

export default function NameToken(props: IProps) {
  const [showConfirmScreen, setShowConfirmScreen] = react.useState(false);
  const [selectedToken, setSelectedToken] = react.useState<TokenInfo | null>(
    null
  );

  const fee_token = props.raceData.fee_token
    ? props.raceData.fee_token
    : TokenType.USDT;

  const tokenDisplayName = formatTokenName(fee_token);

  const allTokens = useAppSelector(selectAllTokens);

  const joinStatus = props.joinStatus;
  const balances = useAppSelector(selectBalances);
  //TODO: filter by allowed tokens such as the tokenFamilies allowed or race classes etc

  const [allowedTokens, setAllowedTokens] = react.useState<TokenInfo[]>([]);

  react.useEffect(() => {
    let tokens: TokenInfo[] = [];
    allTokens.map((token) => {
      if (
        props.raceData.raceClass !== "Open" &&
        props.raceData.raceClass !== "Chance" &&
        props.raceData.raceClass !== token.raceClass
      )
        return;
      if (
        props.raceData.entrants.findIndex(
          (t) =>
            t.tokenId === token.tokenId && t.tokenFamily === token.tokenFamily
        ) === -1
      ) {
        tokens.push(token);
      }
    });

    setAllowedTokens(tokens);
  }, [props.raceData, props.joinStatus]);

  return (
    <>
      <div className="bg">
        {!showConfirmScreen && (
          <>
            <div className="close" onClick={() => props.handleClose()}>
              X
            </div>
            <div className="container">
              <div className="title">Join a race</div>
              <div className="subtitle">
                {props.raceData.raceName} at{" "}
                {props.raceData.raceLocation || "BetNFT"}
              </div>
              <div className="amounts">
                <span className="text-green">Prize Pool:</span>
                {token2DecimalDisplay(
                  props.raceData.prizePool || "0",
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </div>
              <div className="amounts">
                <span>Entry Fee:</span>
                {token2DecimalDisplay(
                  props.raceData.entryFee || "0",
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </div>
              <div className="amounts">
                <span>In-Game Balance:</span>
                {token2DecimalDisplay(balances[fee_token], fee_token)}{" "}
                {tokenDisplayName}
              </div>
              <div className="amounts text-yellow text-center">
                Only your eligible dogs for this race will be shown
              </div>
              <div className="tokens">
                {allowedTokens.map((token, index) => {
                  let bloodlineAttr = token.metadata?.attributes.findIndex(
                    (attr) => attr.trait_type === "Bloodline"
                  );
                  let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
                    token.metadata!.attributes[bloodlineAttr!].value
                  );

                  if (
                    props.raceData.entrants.findIndex(
                      (t) =>
                        t.tokenId === token.tokenId &&
                        t.tokenFamily === token.tokenFamily
                    ) === -1
                  )
                    return (
                      <div
                        className="row-popup"
                        key={token.tokenFamily + token.tokenId}
                      >
                        <div className="dog">
                          <div className="img-container">
                            <img
                              src={getTokenImage(token.metadata?.image!)}
                              className="join-dog"
                            ></img>
                          </div>

                          {/* <img src={DividerTest} className="divider"></img> */}
                          <div className="name-type">
                            <div className="name">{token.metadata?.name}</div>
                            <div className="type">
                              Class - {token.raceClass}
                            </div>
                          </div>
                          <button
                            disabled={joinStatus.isLoading}
                            className="join-button"
                            onClick={() => {
                              props.handleReset();
                              setSelectedToken(token);
                              setShowConfirmScreen(true);
                            }}
                          >
                            Join
                          </button>
                        </div>
                      </div>
                    );
                })}
              </div>
              {allowedTokens.length < 1 && (
                <div className="no-tokens-text">
                  You don't have any dogs for this race. Please visit your
                  <Link to="/kennel">kennel</Link>or
                  <Link to="/" target="_blank">
                    buy a valid
                  </Link>
                  dog.
                </div>
              )}
            </div>
            <img src={LogoWShadow} alt="logo" className="logo" />
          </>
        )}
        {showConfirmScreen && (
          <>
            <ConfirmScreen
              key={selectedToken?.tokenId! + selectedToken?.tokenFamily! || 0}
              handleJoin={props.handleJoin}
              tData={props.raceData}
              tokenData={selectedToken!}
              joinStatus={joinStatus}
              handleBack={() => {
                setSelectedToken(null);
                setShowConfirmScreen(false);
              }}
              error={props.error}
            ></ConfirmScreen>
          </>
        )}
      </div>
    </>
  );
}

interface ConfirmProps {
  handleJoin: (tokenFamily: string, tokenId: number) => void;
  handleBack: () => void;
  tData: IRace;
  joinStatus: UseMutationResult;
  tokenData: TokenInfo;
  children?: react.ReactNode;
  error?: string;
}

const ConfirmScreen = ({
  tokenData,
  handleJoin,
  handleBack,
  joinStatus,
  tData,
  error,
}: ConfirmProps) => {
  let bloodlineAttr = tokenData.metadata?.attributes.findIndex(
    (attr) => attr.trait_type === "Bloodline"
  );
  let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
    tokenData.metadata!.attributes[bloodlineAttr!].value
  );

  const [disableButton, setDisableButton] = react.useState(false);

  react.useEffect(() => {
    if (joinStatus.isIdle) {
      setDisableButton(false);
    }
  }, [joinStatus.isSuccess]);

  const fee_token = tData.fee_token ? tData.fee_token : TokenType.USDT;
  const tokenDisplayname = formatTokenName(fee_token);

  return (
    <>
      <div className="confirm-screen">
        {joinStatus.isError && (
          <>
            <div onClick={() => handleBack()} className="back-button">
              {"<"}
            </div>
            <div className="center">
              {error || "Unexpected error, please try again later"}
            </div>
          </>
        )}
        {joinStatus.isIdle && (
          <div className="idle-modal">
            <div>
              <div>
                <div onClick={() => handleBack()} className="back-button">
                  {"<"}
                </div>
                <div className="center">{tData.raceName}</div>
                <div className="center">
                  <span className="text-green mr-2">Entry fee </span>
                  {"  "} -{" "}
                  {token2DecimalDisplay(tData.entryFee || "0", fee_token)}{" "}
                  {tokenDisplayname}
                </div>
                <div className="center">{tokenData.metadata?.name}</div>
                <div className="center">
                  {bloodlineTag} - {bloodlineName}
                </div>
                <div className="dog center">
                  <div className="token-image">
                    <img
                      src={getTokenImage(tokenData.metadata?.image!)}
                      className="join-dog"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="center">
                <button
                  className="confirm-button"
                  disabled={disableButton}
                  onClick={() => {
                    setDisableButton(true);
                    handleJoin(tokenData.tokenFamily, tokenData.tokenId);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
            <img src={LogoWShadow} alt="logo" className="logo conf-logo" />
          </div>
        )}
        {joinStatus.isSuccess && (
          <>
            <div onClick={() => handleBack()} className="back-button">
              {"<"}
            </div>
            <div className="center">Joined Successfully!</div>
          </>
        )}

        {joinStatus.isLoading && (
          <>
            <div onClick={() => handleBack()} className="back-button">
              {"<"}
            </div>
            <div className="center">
              <CircularProgress />
            </div>
          </>
        )}
      </div>
    </>
  );
};
