interface TooltipProps {
  children: React.ReactNode;
}

export function Tooltip({ children }: TooltipProps) {
  return (
    <div className="custom-tooltip info-tooltip-menu prize-pooltooltip">
      <div className="innerdiv">
        <label>{children}</label>
      </div>
    </div>
  );
}
