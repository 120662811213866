import * as react from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  selectAccount,
  selectSignature,
  setAccount,
  setSignature,
  setLottoMint1Tokens,
  setG1Tokens,
  setG2Tokens,
  setG3Tokens,
  setG4Tokens,
  setNonce,
  selectNonce,
} from "../store/mainSlice";
import Navbar from "./navbar";
import Content from "./content";
import Footer from "./common/Footer";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";

import { connectWallet, signMessage } from "../tools/wallet";
import { getCurrentUser } from "./../services/auth";
import { User } from "./../types";
import { getTokensOwned } from "./../services/queries";
import { updateBalances } from "../store/accountSlice";
import { CommanContentfn } from "../hooks/CommanContext";

let websocket = new WebSocket(
  process.env.REACT_APP_WSS_URL! || "wss://localhost:3000/"
);
export const themeOptions = {};

const theme = createTheme(themeOptions);

export const AccountContext = react.createContext<User | undefined>(undefined);

// Context to trigger re-fetching of token data
export const TokenContext = react.createContext<{
  refetch: () => void;
  isFetching: boolean;
}>({
  refetch: () => {},
  isFetching: false,
});

export default function Main() {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const signature = useAppSelector(selectSignature);
  const nonce = useAppSelector(selectNonce);
  const [currentUser, setCurrentUser] = react.useState<User | undefined>(
    undefined
  );

  const [currentNetwork, setCurrentNetwork] = react.useState("");
  const [error, setError] = react.useState("");
  const [isTokenDataFetching, setIsTokenDataFetching] = react.useState(false);

  websocket.onopen = () => {};
  websocket.onmessage = async (evt) => {
    if (evt.data) {
      let data = JSON.parse(evt.data);

      if (data.nonce) {
        dispatch(setNonce(data.nonce));
        // setTimeout(async () => {
        //   const signature = await signMessage(data.nonce);
        //   dispatch(setSignature(signature));
        // }, 1000);
      }
    }
  };
  websocket.onerror = (evt) => {};
  websocket.onclose = (evt) => {};

  const dispatchWrapper = (address: string) => {
    dispatch(setAccount(address));
  };

  const connectAndSign = async () => {
    try {
      let address = await connectWallet(
        currentNetwork,
        dispatchWrapper,
        setCurrentNetwork,
        setError
      );

      const signature = await signMessage(nonce);
      dispatch(setSignature(signature!));
      await updateAll(address);
    } catch (e) {
      console.log(e);
    }

    //load user dogs after signature
  };

  const updateAll = async (address: string) => {
    let success = await Promise.all([
      await getTokensOwned(address, "LottoMint1"),
      await getTokensOwned(address, "G1"),
      await getTokensOwned(address, "G2"),
      await getTokensOwned(address, "G3"),
      await getTokensOwned(address, "G4"),
    ]);
    if (success) {
      dispatch(setLottoMint1Tokens(success[0]));
      dispatch(setG1Tokens(success[1]));
      dispatch(setG2Tokens(success[2]));
      dispatch(setG3Tokens(success[3]));
      dispatch(setG4Tokens(success[4]));
    }
  };

  const loadTokens = async (address: string) => {
    if (address) {
      try {
        setIsTokenDataFetching(true);
        await updateAll(address);
      } catch (e) {
        console.log(e);
      }
      setIsTokenDataFetching(false);
    }
  };

  react.useEffect(() => {
    if (account === "") return;
    loadTokens(account);
    dispatch(updateBalances());
  }, [account]);

  react.useEffect(() => {
    if (account === "") return;
    dispatch(updateBalances());
  }, [signature]);

  react.useEffect(() => {
    const interval = setInterval(() => {
      if (account === "") return;
      dispatch(updateBalances());
    }, 45000); // 30000 milliseconds = 30 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  react.useEffect(() => {
    const decodedJWT = getCurrentUser();

    setCurrentUser(decodedJWT);
  }, [getCurrentUser]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CommanContentfn>
          <AccountContext.Provider value={currentUser}>
            <TokenContext.Provider
              value={{
                refetch: () => loadTokens(account),
                isFetching: isTokenDataFetching,
              }}
            >
              <Navbar
                currentUsername={currentUser && currentUser.username}
                handleConnect={connectAndSign}
              ></Navbar>
              <Content address={account} networkId={currentNetwork}></Content>
              <Footer></Footer>
              <Snackbar
                open={!!error}
                onClose={() => setError("")}
                autoHideDuration={5000}
                message={error}
              ></Snackbar>
            </TokenContext.Provider>
          </AccountContext.Provider>
        </CommanContentfn>
      </ThemeProvider>
    </>
  );
}
