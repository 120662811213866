import * as react from "react";
import "./kennelsettings.scss";

import LogoWShadow from "../../assets/logowshadow.png";
import PlaceholderKennel from "../../assets/placeholder_kennel.png";
import { UseMutationResult } from "@tanstack/react-query";

interface IProps {
  handleSubmitName: (kennelName: string, email: string) => void;
  handleClose: () => void;
  children?: react.ReactNode;
  error?: string;
  mutation: UseMutationResult<any>;
}

export default function KennelSettings(props: IProps) {
  const mutationdata = props.mutation.data
    ? props.mutation.data.data
    : undefined;
  const [inputValue, setInputValue] = react.useState("");
  const [email, setEmail] = react.useState("");
  const [newImg, setNewImg] = react.useState<string | null>(null);
  const uploadFile = react.useRef<HTMLInputElement>(null);

  react.useEffect(() => {
    if (mutationdata) {
      setInputValue(mutationdata.name);
    }
  }, [mutationdata]);

  const handleImageChange = () => {
    const files = uploadFile.current?.files?.[0];
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setNewImg(reader.result as string);
      };
      reader.readAsDataURL(files);
    }
  };

  return (
    <div className="settings-bg-main">
      <div className="settings-bg">
        <div className="container">
          <div className="title">Kennel Setup</div>
          <div className="input-wrapper">
            <label htmlFor="dog-name" className="input-label">
              Kennel Name *
            </label>
            <input
              disabled={mutationdata ? mutationdata.edited : false}
              placeholder={
                props.mutation.data?.data.name || "Enter a Kennel Name"
              }
              value={inputValue}
              type="text"
              name="dog-name"
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className="conditional">
            * Inappropriate names will not be accepted
          </div>
          <div className="input-wrapper">
            <label htmlFor="email" className="input-label">
              EMAIL
            </label>
            <input
              placeholder={
                mutationdata ? mutationdata.email : "Enter your email"
              }
              value={email}
              id="email"
              type="email"
              name="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="kennel-image">
          <span>Kennel Image</span>
          <img
            onClick={() => uploadFile.current?.click()}
            src={newImg || PlaceholderKennel}
            alt="Kennel"
            className="kennel-img-preview"
          />
          <input
            type="file"
            accept="image/*"
            ref={uploadFile}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
      </div>
      <div>
        <div className="button-row">
          <button
            className="save"
            onClick={() => props.handleSubmitName(inputValue, email)}
            disabled={props.mutation.isLoading}
          >
            Save
          </button>
          <button
            className="discard"
            onClick={props.handleClose}
            disabled={props.mutation.isLoading}
          >
            Discard
          </button>
        </div>
        {props.error && <div className="error">{props.error}</div>}
      </div>
      <img
        src={LogoWShadow}
        alt="Kennel Settings Logo"
        className="kennelsetting-logo"
      />
    </div>
  );
}
