import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "../app/store";
import { TokenInfo } from "../types";
import { getTokensOwned } from "../services/queries";

export interface MainState {
  account: string;
  signature: string;
  LottoMint1: TokenInfo[];
  G1Tokens: TokenInfo[];
  G2Tokens: TokenInfo[];
  G3Tokens: TokenInfo[];
  G4Tokens: TokenInfo[];
  counter: number;
  nonce: string | number;
  fetchingStatus: {
    LottoMint1: boolean;
    G1: boolean;
    G2: boolean;
    G3: boolean;
    G4: boolean;
  };
}

const initialState: MainState = {
  account: "",
  signature: "",
  LottoMint1: [],
  G1Tokens: [],
  G2Tokens: [],
  G3Tokens: [],
  G4Tokens: [],
  counter: 0,
  nonce: "",
  fetchingStatus: {
    LottoMint1: false,
    G1: false,
    G2: false,
    G3: false,
    G4: false,
  },
};

export interface TokenFamily {
  tokenFamily: "G1" | "G2" | "G3" | "G4" | "LottoMint1";
}

export const updateTokenInfo = createAsyncThunk<
  [TokenInfo[] | undefined, string],
  string,
  { state: RootState }
>(
  "main/updateTokenInfo",
  async (tokenFamily, { getState, rejectWithValue }) => {
    const { main } = getState() as { main: MainState };
    // Check if already fetching for this token family
    if (main.fetchingStatus[tokenFamily as keyof typeof main.fetchingStatus]) {
      return rejectWithValue(`Already fetching ${tokenFamily} tokens`);
    }
    let address = main.account;
    // if this is already fetching, return
    let data = await getTokensOwned(address, tokenFamily);
    return [data, tokenFamily];
  }
);

export const mainSlice = createSlice({
  name: "main",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAccount: (state, account) => {
      state.account = account.payload;
    },
    setSignature: (state, sig) => {
      state.signature = sig.payload;
    },
    setLottoMint1Tokens: (state, tokens) => {
      state.LottoMint1 = tokens.payload;
    },
    setG1Tokens: (state, tokens) => {
      state.G1Tokens = tokens.payload;
    },
    setG2Tokens: (state, tokens) => {
      state.G2Tokens = tokens.payload;
    },
    setG3Tokens: (state, tokens) => {
      state.G3Tokens = tokens.payload;
    },
    setG4Tokens: (state, tokens) => {
      state.G4Tokens = tokens.payload;
    },
    setNonce: (state, nonce) => {
      state.nonce = nonce.payload;
    },
    incCounter: (state) => {
      state.counter += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTokenInfo.pending, (state, action) => {
        console.log("pending", action);
        const tokenFamily = action.meta.arg;
        state.fetchingStatus[tokenFamily as keyof typeof state.fetchingStatus] =
          true;
      })
      .addCase(updateTokenInfo.fulfilled, (state, action) => {
        const tokenFamily = action.payload[1];
        // Reset fetching status
        state.fetchingStatus[tokenFamily as keyof typeof state.fetchingStatus] =
          false;

        // Update token arrays
        if (tokenFamily === "LottoMint1") {
          state.LottoMint1 = action.payload[0]
            ? action.payload[0]
            : state.LottoMint1;
        }
        if (tokenFamily === "G1") {
          state.G1Tokens = action.payload[0]
            ? action.payload[0]
            : state.G1Tokens;
        }
        if (tokenFamily === "G2") {
          state.G2Tokens = action.payload[0]
            ? action.payload[0]
            : state.G2Tokens;
        }
        if (tokenFamily === "G3") {
          state.G3Tokens = action.payload[0]
            ? action.payload[0]
            : state.G3Tokens;
        }
        if (tokenFamily === "G4") {
          state.G4Tokens = action.payload[0]
            ? action.payload[0]
            : state.G4Tokens;
        }
      })
      .addCase(updateTokenInfo.rejected, (state, action) => {
        const tokenFamily = action.meta.arg;
        // Reset fetching status on rejection
        state.fetchingStatus[tokenFamily as keyof typeof state.fetchingStatus] =
          false;
      });
  },
});

export const {
  setAccount,
  setSignature,
  setLottoMint1Tokens,
  setG1Tokens,
  setG2Tokens,
  setG3Tokens,
  setG4Tokens,
  setNonce,
  incCounter,
} = mainSlice.actions;

export const selectAccount = (state: RootState) => state.main.account;
export const selectSignature = (state: RootState) => state.main.signature;
export const selectLottoMint1Tokens = (state: RootState) =>
  state.main.LottoMint1;
export const selectG1Tokens = (state: RootState) => state.main.G1Tokens;
export const selectG2Tokens = (state: RootState) => state.main.G2Tokens;
export const selectG3Tokens = (state: RootState) => state.main.G3Tokens;
export const selectG4Tokens = (state: RootState) => state.main.G4Tokens;
export const selectAllTokens = (state: RootState) => [
  ...state.main.G1Tokens,
  ...state.main.G2Tokens,
  ...state.main.G3Tokens,
  ...state.main.G4Tokens,
  ...state.main.LottoMint1,
];
export const selectNonce = (state: RootState) => state.main.nonce;
export const selectCounter = (state: RootState) => state.main.counter;
export const selectTokenFamilyFetchingStatusAll = (state: RootState) =>
  state.main.fetchingStatus;
export const selectTokenFamilyFetchingStatus = (
  state: RootState,
  tokenFamily: string
) =>
  state.main.fetchingStatus[
    tokenFamily as keyof typeof state.main.fetchingStatus
  ];
export default mainSlice.reducer;
