import * as react from "react";
import { formatTokenName, token2DecimalDisplay } from "../../../tools/helper";
import { OrdinalMap } from "../../utils";
import { useParams } from "react-router";
import { trimBloodlineAttribute } from "../kennel";
import {
  getTokenImage,
  useCurrentRace,
  getTokenMetadata,
} from "../../../services/queries";
import PlaceholderDog from "../../assets/placeholderdog.png";
import Box1 from "../../assets/results/box-1.png";
import Box2 from "../../assets/results/box-2.png";
import Box3 from "../../assets/results/box-3.png";
import Box4 from "../../assets/results/box-4.png";
import Box5 from "../../assets/results/box-5.png";
import Box6 from "../../assets/results/box-6.png";
import Box7 from "../../assets/results/box-7.png";
import Box8 from "../../assets/results/box-8.png";
import { IRace, TokenInfo, TokenMetadata, TokenType } from "../../../types";
interface SummaryProps {
  raceData?: IRace; //dace data

  fromLiveRace?: boolean;
}

export default function RaceSummary(props: SummaryProps) {
  const { uuid } = useParams(); //RaceID
  const { raceData, fromLiveRace } = props;
  const { data, isLoading, isSuccess, refetch } = useCurrentRace(uuid!);
  const [tknData, setTknData] = react.useState<TokenMetadata[] | undefined>(
    undefined
  );
  const [raceInfo, setRaceInfo] = react.useState<any>(
    raceData || data || undefined
  );
  react.useEffect(() => {
    if (!fromLiveRace) {
      refetch();
    }
  }, []);

  const updateTokenInfo = react.useCallback(async () => {
    if (!raceData) return;
    let toUpdate: any[] = Array(8).fill(0);
    if (raceData.outcome) {
      for (let i = 0; i < raceData.outcome.length; i++) {
        let tokenInfo = await getTokenMetadata(
          raceData.outcome[i].tokenId,
          raceData.outcome[i].tokenFamily
        );

        toUpdate[i] = tokenInfo;
      }
    }

    setTknData(toUpdate);
  }, [raceData]);

  react.useEffect(() => {
    updateTokenInfo();

    setRaceInfo(raceData);
  }, [raceData, data]);
  react.useEffect(() => {
    if (data && isSuccess && !fromLiveRace) {
      setRaceInfo(data.data);
    }
  }, [data]);

  const prizePool = raceInfo?.prizePool;
  const fee_token: TokenType =
    raceInfo && raceInfo.fee_token ? raceInfo.fee_token : TokenType.USDT;
  const tokenDisplayName = formatTokenName(fee_token);

  return (
    <>
      <div className="race-summary">
        {tknData && tknData?.length > 0 && raceInfo ? (
          <>
            <div className="onefour">
              {raceInfo.outcome.map((item: any, index: number) => {
                // let tokenIndex = tknData?.findIndex((t, index) => {
                //   if (index === item.joinPos - 1) return t;
                // });
                let token = tknData[index];

                let bloodlineAttrIndex = token.attributes.findIndex(
                  (attr: any) =>
                    attr.trait_type === "Bloodline" ||
                    attr.trait_type === "bloodline"
                );
                let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
                  token.attributes[bloodlineAttrIndex!].value
                );
                if (index < 4)
                  return (
                    <div className="position-row" key={index + "row"}>
                      <div className="position-index">
                        {OrdinalMap[(index + 1) as keyof typeof OrdinalMap]}
                      </div>
                      <div className="position-info">
                        <div className="img-container">
                          <img
                            src={getTokenImage(token.image) || PlaceholderDog}
                            alt="dawg"
                          />
                        </div>
                        <div className="detail">
                          <div className="top">
                            <img
                              src={
                                BoxPositionMap[
                                  (item.joinPos +
                                    1) as keyof typeof BoxPositionMap
                                ]
                              }
                            ></img>
                            <div className="top-1">
                              <span className="name">
                                {tknData[index!].name || "Loading..."}
                              </span>
                            </div>

                            <div className="top-2">
                              <span className="extra">TIME</span>
                              <span className="time">
                                {numToSeconds(item.time)} secs
                              </span>
                            </div>
                          </div>
                          <div className="bottom">
                            <div></div>
                            <div className="bot-1">
                              <span className="bloodline">
                                {bloodlineTag + " - " + bloodlineName}
                              </span>
                            </div>
                            <div className="bot-2">
                              <span className="bloodline">{item.kennel}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
            <div className="fiveeight">
              {raceInfo.outcome.map((item: any, index: number) => {
                // let tokenIndex = tknData?.findIndex(
                //   (t) => t.edition.toString() === item.tokenId.toString()
                // );
                let token = tknData[index];

                let bloodlineAttrIndex = token.attributes.findIndex(
                  (attr: any) =>
                    attr.trait_type === "Bloodline" ||
                    attr.trait_type === "bloodline"
                );
                let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
                  token.attributes[bloodlineAttrIndex!].value
                );
                if (index > 3)
                  return (
                    <>
                      <div className="position-row" key={index + "row"}>
                        <div className="position-index">
                          {OrdinalMap[(index + 1) as keyof typeof OrdinalMap]}
                        </div>
                        <div className="position-info">
                          <div className="img-container">
                            <img
                              src={getTokenImage(token.image) || PlaceholderDog}
                              alt="dawg"
                            />
                          </div>
                          <div className="detail">
                            <div className="top">
                              <img
                                src={
                                  BoxPositionMap[
                                    (item.joinPos +
                                      1) as keyof typeof BoxPositionMap
                                  ]
                                }
                              ></img>
                              <div className="top-1">
                                <span className="name">
                                  {tknData[index].name || "Loading..."}
                                </span>
                              </div>

                              <div className="top-2">
                                <span className="extra">TIME</span>
                                <span className="time">
                                  {numToSeconds(item.time)} secs
                                </span>
                              </div>
                            </div>
                            <div className="bottom">
                              <div></div>
                              <div className="bot-1">
                                <span className="bloodline">
                                  {bloodlineTag + " - " + bloodlineName}
                                </span>
                              </div>
                              <div className="bot-2">
                                <span className="bloodline">{item.kennel}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
              })}
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
      {tknData && tknData?.length > 0 && raceInfo && (
        <div className="pricePool">
          <div className="pricePool-title">
            <div>Total prize pool of</div>
            <span className="tittle-amount">
              {token2DecimalDisplay(prizePool || 0, fee_token)}{" "}
              {tokenDisplayName}
            </span>
            <div>will be divided as follows:</div>
          </div>
          <div className="pool-1">
            <div className="pricePool-rank">
              1st -{" "}
              <span className="pricePool-amount">
                {token2DecimalDisplay(
                  raceInfo.prizeStructure[0] || 0,
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </span>
            </div>
            <div className="pricePool-rank">
              2nd -{" "}
              <span className="pricePool-amount">
                {token2DecimalDisplay(
                  raceInfo.prizeStructure[1] || 0,
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </span>
            </div>
            <div className="pricePool-rank">
              3rd -{" "}
              <span className="pricePool-amount">
                {token2DecimalDisplay(
                  raceInfo.prizeStructure[2] || 0,
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </span>
            </div>
            <div className="pricePool-rank">
              4th -{" "}
              <span className="pricePool-amount">
                {token2DecimalDisplay(
                  raceInfo.prizeStructure[3] || 0,
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </span>
            </div>
          </div>
          <div className="pool-1">
            <div className="pricePool-rank">
              5th -{" "}
              <span className="pricePool-amount">
                {token2DecimalDisplay(
                  raceInfo.prizeStructure[4] || 0,
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </span>
            </div>
            <div className="pricePool-rank">
              6th -{" "}
              <span className="pricePool-amount">
                {token2DecimalDisplay(
                  raceInfo.prizeStructure[5] || 0,
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </span>
            </div>
            <div className="pricePool-rank">
              7th -{" "}
              <span className="pricePool-amount">
                {token2DecimalDisplay(
                  raceInfo.prizeStructure[6] || 0,
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </span>
            </div>
            <div className="pricePool-rank">
              8th -{" "}
              <span className="pricePool-amount">
                {token2DecimalDisplay(
                  raceInfo.prizeStructure[7] || 0,
                  fee_token
                )}{" "}
                {tokenDisplayName}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="grad-bottom"></div>
    </>
  );
}

function numToSeconds(num: number) {
  let seconds = num;

  if (!seconds) {
    return "0";
  }

  return seconds.toPrecision(5);
}

export const BoxPositionMap = {
  1: Box1,
  2: Box2,
  3: Box3,
  4: Box4,
  5: Box5,
  6: Box6,
  7: Box7,
  8: Box8,
};
