export enum TokenType {
  USDT = "USDT",
  BETNFT = "BETNFT",
}

export interface IRace {
  _id?: string;
  raceName: string;
  raceClass: RaceRaceClass;
  raceDistance: number;
  startTime: number;
  raceLocation: string;
  fee_token: TokenType | null;
  entryFee: string | null;
  prizePool: string | null;
  prizeStructure: string[];
  prizePercentages: number[];
  entrants: Entrant[];
  maxEntrants?: number;
  raceTime?: number;
  raceSpeeds?: number[][]; //array of arrays of speeds
  raceDists?: number[][]; //array of arrays of distances
  raceId: string;
  raceOwner: string;
  joinOrder: number[];
  raceWeather: string;
  raceCondition: string;
  completed?: boolean;
  cancelled?: boolean;
  sponsorImage?: string;
  sponsorMessage?: string;
  sponsorLink?: string;
  outcome?: any[];
  status?: TournamentStatus; //waiting, pending, completed, cancelled
  type: RaceType; //regular or tournament
  tournamentId?: string;
  rake: number;
  trackIndex?: number;
  extraData?: {
    snapshotId?: string;
  };
}

export interface Entrant {
  tokenId: number;
  tokenFamily: string;
  ownerAddress: string;
  kennel: string;
  joinPos: number;
  experience: number;
  extraData?: any;
}

//TODO: Add other attributes
export interface TokenInfo {
  tokenId: number;
  ownerAddress: string;
  name?: string;
  tokenFamily: string; // contract name (LottoMint1, G1-G4 etc...)
  raceClass: string;
  currentLevelExperience: number;
  totalExperience: number;
  currentLevel: number;
  stopExperienceGain: boolean;
  URI?: any; //TODO: make real type
  metadata?: TokenMetadata;
  kennel?: string;
}
export interface Token {
  _id?: string;
  name?: string;
  ownerAddress: string;
  tokenId: number;
  tokenFamily: string; //G1, G2, G3, G4, LottoMint1
  bloodline: string;
  raceClass: TokenRaceClass;
  races: string[];
  career: {
    firsts: number;
    seconds: number;
    thirds: number;
  };
  completed_races: number;
  total_winnings: string;
  new_total_earnings: {
    [key in TokenType]: string;
  };
  totalExperience: number;
  currentLevelExperience: number;
  stopExperienceGain: boolean;
  resumeExperienceGain: boolean;
  currentLevel: number;
}
export interface TokenMetadata {
  name: string;
  description: string;
  image: string;
  attributes: any[];
  date: string | number;
  dna: string;
  edition: number;
  compiler: string;
  kennelName?: string; //new property
}

export interface Token {
  _id?: string;
  name?: string;
  ownerAddress: string;
  tokenId: number;
  tokenFamily: string; //G1, G2, G3, G4, LottoMint1
  bloodline: string;
  raceClass: TokenRaceClass;
  races: string[];
  career: {
    firsts: number;
    seconds: number;
    thirds: number;
  };
  completed_races: number;
  total_winnings: string;
  new_total_earnings: {
    [key in TokenType]: string;
  };
  totalExperience: number;
  currentLevelExperience: number;
  stopExperienceGain: boolean;
  currentLevel: number;
}

export interface User {
  username: string;
  email: string;
  password?: string;
  address?: string;
}
export interface Tournament {
  _id?: any;
  id: string;
  name: string;
  t_class: string;
  startTime: number;
  cutoffTime: number;
  location: string;
  fee_token: TokenType | null;
  entryFee: string;
  prizePool: string;
  prizeStructure: string[];
  prizePercentages: number[];
  totalRounds: number;
  sponsorImage: string;
  sponsorMessage: string;
  sponsorLink: string;
  maxEntrants: number;
  minEntrants: number;
  entrants: any[];
  rawEntrantCount?: number;
  pointStructure: number[]; //point structure of tournament
  completedRaces: string[]; //array of raceIds
  races: string[];
  raceData?: IRace[];
  owner: string;
  status: TournamentStatus;
  type: string;
  format: string;
}

export interface TournamentEntrant {
  tokenId: number;
  tokenFamily: string;
  ownerAddress: string;
  kennel: string;
  joinPosition: string;
  points: number;
  metadata?: TokenMetadata;
}

export type TournamentStatus =
  | "waiting"
  | "pending"
  | "confirmed"
  | "cancelled"
  | "canceled"
  | "completed";

export enum TokenBloodline {
  G1 = "G1",
  G2 = "G2",
  G3 = "G3",
  G4 = "G4",
}
// This is for token family (which smart contract it was minted from)
export enum TokenFamily {
  G1 = "G1",
  G2 = "G2",
  G3 = "G3",
  G4 = "G4",
  LottoMint1 = "LottoMint1",
}

export enum TokenRaceClass {
  G1 = "G1",
  G2 = "G2",
  G3 = "G3",
  G4 = "G4",
}

export enum RaceRaceClass {
  G1 = "G1",
  G2 = "G2",
  G3 = "G3",
  G4 = "G4",
  Open = "Open",
  Chance = "Chance",
}

export type RaceType =
  | "" // old races have no type so we need to account for that
  | "regular"
  | "regular-custom-aggregation"
  | "tournament-qualifying"
  | "tournament-final";
