import { useQuery, useMutation, useQueries } from "@tanstack/react-query";
import { ethers } from "ethers";
import { axiosInstance, BetNFTContracts } from "./../config/config";
import { Token, TokenInfo, TokenMetadata } from "../types";
import axios from "axios";
import { trimBloodlineAttribute } from "../components/contents/kennel";
import { isAddress } from "ethers/lib/utils";

export const useRegisterUser = () =>
  useMutation(["register"], (data: any) => {
    return axiosInstance.post("/users/register", data);
  });

export const useLogin = () =>
  useMutation(["login"], (data: any) => {
    return axiosInstance.post("/users/login", data);
  });

export const useTournamentRacesQuery = (raceIds: string[]) =>
  useQueries({
    queries: raceIds.map((_, index) => {
      let raceId = raceIds[index];
      return {
        queryKey: [raceId],
        queryFn: () =>
          axiosInstance.get(`/races/${raceId}`, {
            params: {
              raceId: raceId,
            },
          }),
      };
    }),
  });

export const useTournament = () => {
  return useQuery(["tournament"], () => {
    return axiosInstance.get("/tournaments");
  });
};

export const useCurrentTournament = (tournamentId: string) => {
  return useQuery(
    [tournamentId],
    () => {
      return axiosInstance.get(`/tournaments/${tournamentId}`, {
        params: {
          raceId: tournamentId,
        },
      });
    },
    { refetchOnWindowFocus: false }
  );
};
export const useJoinTournament = () => {
  return useMutation(["join-tournament"], (data: any) => {
    return axiosInstance.post("/tournaments/join", data);
  });
};

export const useAllRaces = () => {
  return useQuery(["racelist"], () => {
    return axiosInstance.get("/races");
  });
};

//for completed (successful) races
export const useCompletedRaces = () => {
  return useQuery(["finishedRaces"], () => {
    return axiosInstance.get("/races/completed");
  });
};

export const useCurrentRace = (raceId: string, onError?: () => void) => {
  return useQuery(
    [raceId],
    () => {
      return axiosInstance.get(`/races/${raceId}`, {
        params: {
          raceId: raceId,
        },
      });
    },
    { refetchOnWindowFocus: false, onError: onError }
  );
};

export const useUserHistory = (address: string, onError?: () => void) => {
  return useQuery(
    [address],
    () => {
      return axiosInstance.get(`/races/history/${address}`);
    },
    { refetchOnWindowFocus: false, onError: onError }
  );
};

export const useRaceHistory = () => {
  return useQuery(["raceHistory"], () => {
    return axiosInstance.get("/races/history");
  });
};
export const useRaceWaiting = () => {
  return useQuery(["raceWaiting"], () => {
    return axiosInstance.get("/races/waiting");
  });
};

export const useTokenInfo = (tokenId: string, tokenFamily: string) => {
  //${tokenFamily}/${tokenId}
  return useQuery(
    [tokenId + tokenFamily], //key
    () => {
      return axiosInstance.get(`/tokens/${tokenFamily}/${tokenId}`, {});
    }
  );
};

export const useHomeContent = () => {
  return useQuery(["homeContent"], () => {
    return axiosInstance.get("/content");
  });
};
export const useUpdateHomeContent = () => {
  return useMutation(["mint"], (data: any) => {
    return axiosInstance.post("/content", data);
  });
};

export const useGetKennel = (address: string) => {
  const enabled = isAddress(address);
  return useQuery(
    ["kennel"],
    () => {
      return axiosInstance.get(`/kennel/user/${address}`);
    },
    {
      refetchOnWindowFocus: false,

      refetchInterval: 10000,
      enabled: enabled,
    }
  );
};

export const useGetKennelLeaderboard = () => {
  return useQuery(
    ["kennel-leaderboard"],
    () => {
      return axiosInstance.get(`/leaderboard/kennels`);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetTokenLeaderboard = (
  bloodlineFilter: string,
  addressFilter: string
) => {
  let filterQuery = "";

  const params = [];

  if (bloodlineFilter && bloodlineFilter !== "All") {
    params.push(`bloodline=${bloodlineFilter}`);
  }

  if (addressFilter && addressFilter !== "") {
    params.push(`address=${addressFilter.toLowerCase()}`);
  }

  if (params.length > 0) {
    filterQuery = "?" + params.join("&");
  }

  return useQuery(
    ["token-leaderboard" + bloodlineFilter + addressFilter],
    () => {
      return axiosInstance.get(`/leaderboard/tokens${filterQuery}`);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetTokenWinsLeaderboard = (
  bloodlineFilter: string,
  period: string,
  paid: boolean
) => {
  let filterQuery = "";

  const params = [];

  if (bloodlineFilter) {
    params.push(`bloodline=${bloodlineFilter}`);
  }
  if (paid) {
    params.push(`paid=${paid}`);
  } else {
    params.push(`free=${true}`);
  }

  if (period) {
    if (period === "Monthly") {
      // calculate days since start of month.

      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

      let days = Math.floor(
        (date.getTime() - firstDay.getTime()) / (1000 * 60 * 60 * 24)
      );
      // push startTimestamp and endTimestamp instead

      let startTimestamp = Math.floor(firstDay.getTime());
      let endTimestamp = Math.floor(date.getTime());

      params.push(`startTimestamp=${startTimestamp}`);
      params.push(`endTimestamp=${endTimestamp}`);
    }
    if (period === "Weekly") {
      let date = new Date();
      let firstDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay()
      );

      let days = Math.floor(
        (date.getTime() - firstDay.getTime()) / (1000 * 60 * 60 * 24)
      );

      let startTimestamp = Math.floor(firstDay.getTime());
      let endTimestamp = Math.floor(date.getTime());

      params.push(`startTimestamp=${startTimestamp}`);
      params.push(`endTimestamp=${endTimestamp}`);
    }
    if (period === "Yearly") {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), 0, 1);

      let days = Math.floor(
        (date.getTime() - firstDay.getTime()) / (1000 * 60 * 60 * 24)
      );

      let startTimestamp = Math.floor(firstDay.getTime());
      let endTimestamp = Math.floor(date.getTime());

      params.push(`startTimestamp=${startTimestamp}`);
      params.push(`endTimestamp=${endTimestamp}`);
    }
  }

  if (params.length > 0) {
    filterQuery = "?" + params.join("&");
  }

  return useQuery(
    ["races-wins-leaderboard" + bloodlineFilter + paid],
    () => {
      return axiosInstance.get(`/races/custom-aggregation${filterQuery}`);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetSnapshotHistory = () => {
  return useQuery(
    ["snapshot-history"],
    () => {
      return axiosInstance.get(`/races/snapshots`);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetSnapshotById = (snapshotId: string) => {
  return useQuery(["snapshot-by-id" + snapshotId], () => {
    return axiosInstance.get(`/races/snapshots/${snapshotId}`);
  });
};

export const useUpdateKennel = () =>
  useMutation(["kennel-info"], (data: any) => {
    return axiosInstance.post("/kennel/update", data);
  });

export const useChangeName = () =>
  useMutation(["changeName"], (data: any) => {
    return axiosInstance.post(`/tokens/rename/`, data);
  });

export const useCreateRace = () =>
  useMutation(["create-race"], (data: any) => {
    return axiosInstance.post("/races/create", data);
  });

export const useJoinRace = () =>
  useMutation(["join-race"], (data: any) => {
    return axiosInstance.post("/races/join", data);
  });

export const useStartRace = () =>
  useMutation(["start-race"], (data: any) => {
    return axiosInstance.post("/races/start", data);
  });

export const usePromoteTokenRaceClass = () => {
  return useMutation(
    ["promote-token"],
    (data: {
      tokenId: number;
      tokenFamily: string;
      signature: string;
      address: string;
    }) => {
      return axiosInstance.post("/tokens/promote", data);
    }
  );
};

export const useResumeTokenExperienceGain = () => {
  return useMutation(
    ["resume-token"],
    (data: {
      tokenId: number;
      tokenFamily: string;
      operation: string;
      signature: string;
      address: string;
    }) => {
      return axiosInstance.post("/tokens/updateExperienceGain", data);
    }
  );
};

export const updateBalance = async (address: string, signature: string) => {
  try {
    let res = await axiosInstance.get(`/accounts`, {
      params: {
        address: address,
        signature: signature,
      },
    });
    return res.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(error);
  }
};

export const getTokensOwned = async (address: string, tokenFamily: string) => {
  const userTokens = await axiosInstance.get(`/tokens/${address}`);
  const foundTokens = userTokens.data.tokens as Token[];
  try {
    const { ethereum } = window as any;

    if (ethereum) {
      const indexOfContract = BetNFTContracts.findIndex(
        (x) => x.name === tokenFamily
      );
      const tokenFamilyConfig = BetNFTContracts[indexOfContract];
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        tokenFamilyConfig.contract,
        tokenFamilyConfig.ABI,
        signer
      );

      let balance = await connectedContract.balanceOf(address);
      let tokens: TokenInfo[] = [];

      for (let i = 0; i < balance.toNumber(); i++) {
        let idx = await connectedContract.tokenOfOwnerByIndex(address, i);
        let data = await getTokenMetadata(idx.toNumber(), tokenFamily);
        const token = foundTokens.find(
          (x) => x.tokenId === idx.toNumber() && x.tokenFamily === tokenFamily
        );
        let bloodlineAttrIndex = data.attributes.findIndex(
          (attr: any) => attr.trait_type === "Bloodline"
        );

        let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
          data.attributes[bloodlineAttrIndex!].value
        );
        tokens.push({
          tokenId: idx.toNumber(), //BN
          ownerAddress: address,
          raceClass: token ? token.raceClass : bloodlineTag,
          tokenFamily: tokenFamily,
          metadata: data as TokenMetadata,
          totalExperience: token ? token.totalExperience : 0,
          currentLevelExperience: token ? token.currentLevelExperience : 0,
          currentLevel: token ? token.currentLevel : 1,
          stopExperienceGain: token ? token.stopExperienceGain : false,
        } as TokenInfo);
      }

      return tokens;
    } else {
      return [];
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(error);
  }
};
export const getTokenURI = async (
  tokenId: number,
  contract: ethers.Contract
) => {
  try {
    let uri = await contract.tokenURI(tokenId);
    return uri;
  } catch (e) {
    console.log(e, "URI error");
  }
};

export const getTokenMetadata = async (
  tokenId: number,
  tokenFamily: string //LottoMint1, G1 - G4,
) => {
  if (!tokenFamily) {
    tokenFamily = "LottoMint1";
  }

  try {
    const tokenData = `https://betnft-dev.s3.ap-southeast-2.amazonaws.com/${tokenFamily}/${tokenId}.json`; //Lotto mint jsons
    //move to config file
    let res = await axios.get(tokenData, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    return res.data;
  } catch (e) {
    console.log(e, "URI error");
  }
};

export const getTokenImage = (imageLink: string) => {
  //"ipfs://QmRC7qTcDdRpEmaMWuxJvjQkYrSfM94xuq8tQhb7LFKQyT/260.png" trim this
  const trimmed = imageLink.substring(7, imageLink.length);
  const url = `https://betnft.mypinata.cloud/ipfs/${trimmed}`;

  return url;
};
