const { createContext, useState, useContext } = require("react");

 export const CreateComman = createContext();



export const CommanContentfn = ({ children }) => {
  const [modelshow, setmodelshow] = useState();
  return (
    <CreateComman.Provider value={{ modelshow, setmodelshow }}>
      {children}
    </CreateComman.Provider>
  );
};
