import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "../app/store";
import { MainState } from "./mainSlice";
import { updateBalance } from "../services/queries";
import { TokenType } from "../types";
export interface AccountState {
  address: string; //address of user
  balance: {
    [key in TokenType]: string; // Key is token name e.g USDT, ETH, etc
  };
  signature: string;
}

const initialState: AccountState = {
  address: "",
  balance: {
    USDT: "0",
    BETNFT: "0",
  },
  signature: "",
};

export const updateBalances = createAsyncThunk<AccountState>(
  "account/updateBalances",
  async (_, { getState }) => {
    const { main } = getState() as { main: MainState };
    let signature = main.signature;
    let address = main.account;
    if (!address) throw new Error("No address");
    if (!signature) throw new Error("No signature");
    let info = await updateBalance(address, signature);
    return info;
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAccount: (state, address) => {
      state.address = address.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBalances.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(updateBalances.fulfilled, (state, action) => {
      // Add user balances to the state
      console.log(action);
      let amountUSDT = action.payload.balance[TokenType.USDT] || "0";
      let amountBETNFT = action.payload.balance[TokenType.BETNFT] || "0";
      state.balance[TokenType.USDT] = amountUSDT;
      state.balance[TokenType.BETNFT] = amountBETNFT;
    });
  },
});

export const { setAccount } = accountSlice.actions;

export const selectAddress = (state: RootState) => state.account.address;
export const selectBalances = (state: RootState) => state.account.balance;

export default accountSlice.reducer;



