import { TokenMetadata, TokenType, IRace } from "../../../types";
import { useNavigate } from "react-router";
import * as react from "react";
import { ClassIndicator } from "../../contents/races/racelist";
import PrizeTypeBadge from "../PrizeTypeBadge";
import moment from "moment";
import { formatTokenName, token2DecimalDisplay } from "../../../tools/helper";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import InfoWhite from "../../assets/info-green.svg";
import {
  selectAccount,
  selectNonce,
  selectSignature,
  setSignature,
} from "../../../store/mainSlice";
import { Check } from "@mui/icons-material";
import { calculatePrizeFromStructure } from "../../../tools/helper";
import { canPromoteToken, OrdinalMap } from "../../utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import JoinPopup from "../../contents/races/joinpopup";
import {
  getTokenImage,
  getTokenMetadata,
  useJoinRace,
} from "../../../services/queries";
import { trimBloodlineAttribute } from "../../contents/kennel";

import Popup from "../Popup";
import { signMessage } from "../../../tools/wallet";

import { updateBalances } from "../../../store/accountSlice";
import { BoxPositionMap } from "../../contents/races/racesummary";
import WhiteImg from "../../assets/info-white.png";
import { Tooltip } from "../Tooltip";

export const MAX_EXP_LEVEL = 500;

interface IProps {
  race: IRace;
  handleJoin: () => void;
  modelshow?: string | number | undefined;
  setmodelshow?: any;
}

export default function RaceInfoRow({
  race,
  modelshow,
  setmodelshow,
  handleJoin,
}: IProps) {
  const token = race.fee_token ? race.fee_token : TokenType.USDT;
  const [popupmodel, setPopupmodel] = react.useState(false);
  let entryFee = token2DecimalDisplay(race.entryFee?.toString() || "0", token);
  let prizePool = token2DecimalDisplay(
    race.prizePool?.toString() || "0",
    token
  );
  const account = useAppSelector(selectAccount);
  const [showJoinPopup, setShowJoinPopup] = react.useState(false);
  const join = useJoinRace();
  const signature = useAppSelector(selectSignature);
  const nonce = useAppSelector(selectNonce);
  const dispatch = useAppDispatch();
  const [error, setError] = react.useState("");
  const [showWatch3dHover, setShowWatch3dHover] = react.useState(false);

  const joinRace = async (tokenFamily: string, tokenId: number) => {
    setError("");
    try {
      if (!signature) {
        let sig = await signMessage(nonce);
        dispatch(setSignature(sig));
        let res = await join.mutateAsync({
          raceId: race.raceId,
          ownerAddress: account,
          tokenId: tokenId,
          tokenFamily: tokenFamily,
          signature: sig,
        });
      } else {
        let res = await join.mutateAsync({
          raceId: race.raceId,
          ownerAddress: account,
          tokenId: tokenId,
          tokenFamily: tokenFamily,
          signature: signature,
        });
      }
      handleJoin();
      dispatch(updateBalances());
    } catch (e: any) {
      setError(e.response.data);
    }
  };

  const resetMutation = () => {
    join.reset();
  };

  const navigate = useNavigate();
  const [isHovering, setIsHovering] = react.useState("");
  const [hoveredRaces, setHoveredRaces] = react.useState<{
    [key: string]: boolean;
  }>({});
  const parentRef = react.useRef<HTMLDivElement | null>(null);
  const hoverBoxRef = react.useRef<HTMLDivElement | null>(null);

  const adjustHoverBoxPosition = () => {
    if (parentRef.current && hoverBoxRef.current) {
      const hoverBoxRect = hoverBoxRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (hoverBoxRect.bottom > viewportHeight) {
        // The hover box goes beyond the bottom of the viewport
        const difference = hoverBoxRect.bottom - viewportHeight;
        hoverBoxRef.current.style.top = `${-difference}px`;
      } else {
        // Resetting to default position in case the hover box doesn't overlap with viewport's bottom
        hoverBoxRef.current.style.top = "0px";
      }
    }
  };

  const updateHoverBoxPosition = () => {
    adjustHoverBoxPosition();
  };

  react.useEffect(() => {
    if (isHovering === "PrizePool") {
      // We introduce a slight delay to ensure the hover box is rendered
      // before trying to access its ref.
      updateHoverBoxPosition();
    }
  }, [isHovering]);

  let percentage_array = [...Array(race.maxEntrants || 8).keys()];

  const tokenDisplayName = formatTokenName(token);

  const hadlingModelshow = () => {
    setmodelshow(race.raceId);
  };

  const [tokenData, setTokenData] = react.useState<TokenMetadata[] | undefined>(
    undefined
  );

  const handleMouseEnter = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: true }));
  };

  const handleMouseLeave = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: false }));
  };

  const updateTokenInfo = react.useCallback(async () => {
    let toUpdate: any[] = Array(race.maxEntrants || 8).fill(0);
    if (race.entrants) {
      for (let i = 0; i < race.entrants.length; i++) {
        let boxNumber = race.entrants[i].joinPos;
        let tokenInfo = await getTokenMetadata(
          race.entrants[i].tokenId,
          race.entrants[i].tokenFamily
        );

        toUpdate[i] = {
          ...tokenInfo,
          kennelName: race.entrants[i].kennel || "",
          boxNumber: boxNumber,
        };
      }
    }

    setTokenData(toUpdate);
  }, [race]);

  react.useEffect(() => {
    updateTokenInfo();
  }, [race]);

  const closePopup = () => {
    setShowJoinPopup(false);
  };

  const openPopup = async () => {
    setShowJoinPopup(true);
  };

  const shouldShowWatch3D =
    race.entrants.length === race.maxEntrants ||
    (race.maxEntrants === 8 &&
      race.entrants.length >= 2 &&
      race.startTime < Date.now() + 60000);

  return (
    <div className="race-row-contain" style={{ width: "100%" }}>
      {modelshow == race.raceId ? (
        <div
          className="popup-race-row"
          // onClick={() => setPopupmodel(!popupmodel)}
        >
          <div onClick={() => setmodelshow(undefined)} className="close">
            <CloseIcon />
          </div>
          <div className="header">
            <div className="left">
              <h6>
                {race.raceName}
                <span>{race.raceLocation}</span>
              </h6>
              <span
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${window.location.host}/race/${race.raceId}`
                  )
                }
              >
                <ContentCopyIcon
                  sx={{ color: "#6a6f76", ":hover": { color: "#FFF" } }}
                />
              </span>
            </div>

            <div className="right-main">
              <div className="img-cotain">
                <div>
                  <div
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(`/race/${race.raceId}`);
                    }}
                    className="watch-3d"
                    style={{
                      backgroundImage: "url(/2d.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    Watch 2d
                    <OpenInNewIcon />
                  </div>

                  {shouldShowWatch3D ? (
                    <a
                      className="watch-3d"
                      onMouseEnter={() => setShowWatch3dHover(true)}
                      onMouseLeave={() => setShowWatch3dHover(false)}
                      href={`${`${process.env.REACT_APP_3D_URL}/race/${race.raceId}`}`}
                      style={{
                        backgroundImage: "url(/3d.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",

                        position: "relative",
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Watch 3D <OpenInNewIcon />
                      {showWatch3dHover &&
                        race.entrants.length !== race.maxEntrants && (
                          <div className="custom-tooltip">
                            <div className="innerdiv">
                              <label className="">
                                Available once race is filled and/or cut off
                                time is reached.
                              </label>
                            </div>
                          </div>
                        )}
                    </a>
                  ) : (
                    <div
                      className="watch-3d"
                      onMouseEnter={() => setShowWatch3dHover(true)}
                      onMouseLeave={() => setShowWatch3dHover(false)}
                      style={{
                        backgroundImage: "url(/3d.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",

                        position: "relative",
                      }}
                    >
                      Watch 3D <OpenInNewIcon />
                      {showWatch3dHover &&
                        race.entrants.length !== race.maxEntrants && (
                          <div
                            className="custom-tooltip"
                            style={{
                              minWidth: "max-content",
                            }}
                          >
                            <div className="innerdiv">
                              <label className="">
                                Available once race is filled and/or cut off
                                time is reached.
                              </label>
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
                {race.entrants.length !== (race.maxEntrants || 8) && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      openPopup();
                    }}
                  >
                    Join Race
                  </Button>
                )}
              </div>

              <div className="right">
                <div className="avtar">
                  <h6>Entry Fees:-</h6>
                  <span>
                    {race.entryFee === "0" || !race.entryFee
                      ? "Free"
                      : `${entryFee} ${tokenDisplayName}`}{" "}
                  </span>
                </div>

                <div
                  onClick={() => setPopupmodel(!popupmodel)}
                  className="popover_p"
                >
                  <p>Prize pool dividends</p>
                  {popupmodel ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}

                  {popupmodel && (
                    <div
                      // ref={hoverBoxRef}
                      style={{ left: "-11.8rem" }}
                      className="custom-tooltip pricepool-tooltip-cover prize-pooltooltip"
                    >
                      <div className="innerdiv pricepool-tooltip">
                        {percentage_array.map((_, index) => {
                          if (index === 0) {
                            return (
                              <div className="inner-wrapper" key={index}>
                                <span className="rank-gold rank">1ST</span>
                                <span>
                                  {calculatePrizeFromStructure(
                                    race.prizePercentages[index] || 0,
                                    race.prizePool || "0",
                                    token
                                  ).toString()}
                                </span>
                                <span>{tokenDisplayName}</span>
                              </div>
                            );
                          }

                          if (index === 1) {
                            return (
                              <div className="inner-wrapper" key={index}>
                                <span className="rank-silver rank">2ND</span>
                                <span>
                                  {calculatePrizeFromStructure(
                                    race.prizePercentages[index] || 0,
                                    race.prizePool || "0",
                                    token
                                  ).toString()}
                                </span>
                                <span>{tokenDisplayName}</span>
                              </div>
                            );
                          }
                          if (index === 2) {
                            return (
                              <div className="inner-wrapper" key={index}>
                                <span className="rank-bronze rank">3RD</span>
                                <span>
                                  {calculatePrizeFromStructure(
                                    race.prizePercentages[index] || 0,
                                    race.prizePool || "0",
                                    token
                                  ).toString()}
                                </span>
                                <span>{tokenDisplayName}</span>
                              </div>
                            );
                          } else {
                            return (
                              <react.Fragment key={index}>
                                <div className="inner-wrapper">
                                  <span className="rank">
                                    {
                                      OrdinalMap[
                                        (index + 1) as keyof typeof OrdinalMap
                                      ]
                                    }
                                  </span>
                                  <span>
                                    {calculatePrizeFromStructure(
                                      race.prizePercentages[index] || 0,
                                      race.prizePool || "0",
                                      token
                                    ).toString()}
                                  </span>
                                  <span>{tokenDisplayName}</span>
                                </div>
                              </react.Fragment>
                            );
                          }
                        })}
                        <div className="para">
                          <hr
                            style={{
                              position: "absolute",
                              top: "430px",
                              width: "320px",
                              left: "-44px",
                            }}
                          />
                          <p
                            style={{
                              margin: "0px 0px 10px 0px",
                              width: "340px",
                              fontWeight: "800",
                              position: "absolute",
                              top: "450px",
                              left: "-44px",
                              textWrap: "wrap",
                            }}
                          >
                            XP is awarded to every dog that enters a race that
                            runs, except for those that are levelled.
                          </p>
                        </div>
                      </div>

                      <a
                        style={{
                          color: "#13bc41",
                          textDecoration: "none",
                          fontWeight: "800",
                        }}
                        href="https://app.gitbook.com/o/KVg36gIWyzxW8xhAhxon/s/ZtZWoPvx9kAeLgV3rhDX/betnft/experience-points-xp"
                      >
                        Click here for XP payout Info
                      </a>
                    </div>
                  )}
                </div>

                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  Race info
                  <span
                    onMouseEnter={() => handleMouseEnter("info")}
                    onMouseLeave={() => handleMouseLeave("info")}
                    className={`prize-pool grid-item info-tooltip  relative ${
                      hoveredRaces["info"] ? "hovered" : ""
                    }`}
                  >
                    <img src={WhiteImg} className="info" />
                    {hoveredRaces["info"] && (
                      <div className="custom-tooltip info-tooltip-menu prize-pooltooltip">
                        <div className="innerdiv">
                          <span>
                            8 gate races will still run with 2 or more entrants
                            and prize pools adjusted accordingly
                          </span>
                        </div>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row-status">
            <div>
              <div>
                <p>Event Type:-</p>
                <span>{race.raceClass}</span>
                <PrizeTypeBadge
                  prizePercentages={race.prizePercentages}
                ></PrizeTypeBadge>
              </div>

              <div>
                <p>Distance:- </p>
                <span>{race.raceDistance}</span>
              </div>

              <div>
                <p>status:- </p>
                <span>{race.status}</span>
              </div>

              <div>
                <p className="starts">Starts :- </p>
                <span>
                  {" "}
                  {moment(race.startTime).format("DD MMM, YYYY. hh.mm a")}
                </span>
              </div>
            </div>

            <div>
              <p>Registered:-</p>
              <span>
                {race.entrants
                  ? `${race.entrants.length}/${race.maxEntrants || 8}`
                  : `0/${race.maxEntrants || 8}`}
              </span>
            </div>
          </div>

          <hr className="hr" />
          <div className="table">
            <div className="table-header">
              <div>GATE</div>
              <div>GREYHOUND NAME</div>
              <div className="test">BLOODLINE/CLASS</div>
              <div>CAREER STATS</div>
              <div className="test">KENNEL NAME</div>
            </div>

            {tokenData?.map((token: any, index: number) => {
              if (!token) return;
              let bloodlineIndex = token.attributes.findIndex(
                (e: any) => e.trait_type === "Bloodline"
              );
              let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
                token.attributes[bloodlineIndex!].value
              );

              let matchingRaceToken: any = race.entrants.find(
                (entrant) => entrant.joinPos === token.boxNumber
              )!;
              const canPromote = canPromoteToken(matchingRaceToken);
              return (
                <div key={index} className="table-body">
                  <div className="id">
                    {/* <span>{index}</span>{" "} */}
                    <img
                      className="table-index"
                      src={
                        BoxPositionMap[
                          (token.boxNumber + 1) as keyof typeof BoxPositionMap
                        ]
                      }
                    ></img>
                    <img
                      src={token.image ? getTokenImage(token?.image) : "/"}
                    ></img>
                  </div>

                  <div className="racehorse">
                    <p>
                      {token.name} -
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        <div className="lx44" style={{ margin: "5px 0" }}>
                          LV{matchingRaceToken.currentLevel}
                        </div>
                        <div className="lx44_EXP">
                          XP:{" "}
                          <h6>
                            {matchingRaceToken.currentLevelExperience}/
                            {MAX_EXP_LEVEL}
                          </h6>
                        </div>
                        {canPromote && <TokenExpToolTip />}
                      </div>
                    </p>
                  </div>

                  <div className="comman1 distance">
                    <div className="test">
                      {bloodlineTag}/{matchingRaceToken.raceClass}
                    </div>
                  </div>

                  <div className="comman1 date">
                    <p>{`${matchingRaceToken.career.firsts}/${matchingRaceToken.career.seconds}/${matchingRaceToken.career.thirds}`}</p>
                  </div>
                  <div className="comman distance">
                    <div className="test">
                      {token.kennelName || bloodlineTag}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <hr className="hr" />

          {/* <div className="footer">
            <h5>You do not have any racehorses</h5>
            <h6>To enter an event, purchase your first racehorse</h6>
            <button>Go to Marketplace</button>
          </div> */}
        </div>
      ) : (
        <div className="race-row" key={race.raceId} onClick={hadlingModelshow}>
          <div className="race-titles">
            <span>{race.raceName}</span>
            <span>{`"${race.raceLocation}"` || "BetNFT"}</span>
          </div>

          {/* <div className="grid-item">
                          <ClassIndicator>{race.raceClass}</ClassIndicator>
                        </div> */}
          <div className="grid-item event-type-box relative">
            <div
              className="grid-item "
              onMouseOver={() => {
                if (race.raceClass === "Open") {
                  setIsHovering("Open");
                }
                if (race.raceClass === "Chance") {
                  setIsHovering("Chance");
                }
                if (race.raceClass === "G1") {
                  setIsHovering("G1");
                }
                if (race.raceClass === "G2") {
                  setIsHovering("G2");
                }
                if (race.raceClass === "G3") {
                  setIsHovering("G3");
                }
                if (race.raceClass === "G4") {
                  setIsHovering("G4");
                }
              }}
              onMouseOut={() => setIsHovering("")}
            >
              <ClassIndicator>{race.raceClass}</ClassIndicator>
            </div>

            <PrizeTypeBadge
              prizePercentages={race.prizePercentages}
            ></PrizeTypeBadge>
            {isHovering === "Open" ? (
              <div className={`custom-tooltip event-label-tooltip`}>
                <div className="innerdiv">
                  <label className="">Any dog can enter this race</label>
                </div>
              </div>
            ) : null}
            {isHovering === "Chance" ? (
              <div className={`custom-tooltip event-label-tooltip `}>
                <div className="innerdiv">
                  <label className="">Any dog can enter this race</label>
                </div>
              </div>
            ) : null}
            {isHovering === "G1" ? (
              <div className={`custom-tooltip event-label-tooltip`}>
                <div className="innerdiv">
                  <label className="">
                    This class only can enter this race
                  </label>
                </div>
              </div>
            ) : null}
            {isHovering === "G2" ? (
              <div className={`custom-tooltip event-label-tooltip`}>
                <div className="innerdiv">
                  <label className="">
                    This class only can enter this race
                  </label>
                </div>
              </div>
            ) : null}
            {isHovering === "G3" ? (
              <div className="custom-tooltip event-label-tooltip">
                <div className="innerdiv">
                  <label className="">
                    This class only can enter this race
                  </label>
                </div>
              </div>
            ) : null}
            {isHovering === "G4" ? (
              <div className="custom-tooltip event-label-tooltip">
                <div className="innerdiv">
                  <label className="">
                    This class only can enter this race
                  </label>
                </div>
              </div>
            ) : null}
          </div>
          <div className="distance grid-item">{race.raceDistance}m</div>
          <div className="entry-fee grid-item">
            {moment(race.startTime).format("DD MMM, YYYY. hh.mm a")}
          </div>
          <div className="entry-fee-clor grid-item ">
            {race.entryFee === "0" || !race.entryFee
              ? "Free"
              : `${entryFee} ${tokenDisplayName}`}
          </div>

          <div
            onMouseEnter={() => {
              setIsHovering("PrizePool");
              updateHoverBoxPosition();
            }}
            onMouseLeave={() => setIsHovering("")}
            ref={parentRef}
            className={`prize-pool grid-item relative ${
              isHovering === "PrizePool" ? "hovered" : ""
            }`}
          >
            {prizePool} {tokenDisplayName}
            {isHovering === "PrizePool" && race.prizePercentages.length > 0 && (
              <div
                ref={hoverBoxRef}
                style={{ left: "-11.8rem" }}
                className="custom-tooltip pricepool-tooltip-cover prize-pooltooltip"
              >
                <div className="innerdiv pricepool-tooltip">
                  {percentage_array.map((_, index) => {
                    if (index === 0) {
                      return (
                        <div className="inner-wrapper">
                          <span className="rank-gold rank">1ST</span>
                          <span>
                            {calculatePrizeFromStructure(
                              race.prizePercentages[index] || 0,
                              race.prizePool || "0",
                              token
                            ).toString()}
                          </span>
                          <span>{tokenDisplayName}</span>
                        </div>
                      );
                    }

                    if (index === 1) {
                      return (
                        <div className="inner-wrapper">
                          <span className="rank-silver rank">2ND</span>
                          <span>
                            {calculatePrizeFromStructure(
                              race.prizePercentages[index] || 0,
                              race.prizePool || "0",
                              token
                            ).toString()}
                          </span>
                          <span>{tokenDisplayName}</span>
                        </div>
                      );
                    }
                    if (index === 2) {
                      return (
                        <div className="inner-wrapper">
                          <span className="rank-bronze rank">3RD</span>
                          <span>
                            {calculatePrizeFromStructure(
                              race.prizePercentages[index] || 0,
                              race.prizePool || "0",
                              token
                            ).toString()}
                          </span>
                          <span>{tokenDisplayName}</span>
                        </div>
                      );
                    } else {
                      return (
                        <react.Fragment key={index}>
                          <div className="inner-wrapper">
                            <span className="rank">
                              {
                                OrdinalMap[
                                  (index + 1) as keyof typeof OrdinalMap
                                ]
                              }
                            </span>

                            <span>
                              {calculatePrizeFromStructure(
                                race.prizePercentages[index] || 0,
                                race.prizePool || "0",
                                token
                              ).toString()}
                            </span>
                            <span>{tokenDisplayName}</span>
                          </div>
                        </react.Fragment>
                      );
                    }
                  })}
                </div>
                <hr />
                <p
                  style={{
                    margin: "0px 0px 10px 0px",
                    width: "300px",
                    fontWeight: "800",
                  }}
                >
                  XP is awarded to every dog that enters a race that runs,
                  except for those that are levelled.
                </p>
                <a
                  style={{
                    color: "#13bc41",
                    textDecoration: "none",
                    fontWeight: "800",
                  }}
                  href="https://app.gitbook.com/o/KVg36gIWyzxW8xhAhxon/s/ZtZWoPvx9kAeLgV3rhDX/betnft/experience-points-xp"
                >
                  Click here for XP payout Info
                </a>
              </div>
            )}
          </div>

          <div className="registered-racers grid-item">
            {race.entrants
              ? `${race.entrants.length}/${race.maxEntrants || 8}`
              : `0/${race.maxEntrants || 8}`}
            {race.entrants.find(
              (entrant) => entrant.ownerAddress === account
            ) && (
              <>
                <Check></Check>
              </>
            )}
          </div>
        </div>
      )}
      {showJoinPopup && race && (
        <Popup>
          <JoinPopup
            handleJoin={joinRace}
            handleClose={closePopup}
            handleReset={resetMutation}
            raceData={race}
            joinStatus={join}
            error={error}
          ></JoinPopup>
        </Popup>
      )}
    </div>
  );
}

const TokenExpToolTip = () => {
  const [showHover, setShowHover] = react.useState(false);
  return (
    <>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "white",
          marginLeft: "10px",
          cursor: "pointer",
        }}
        onMouseEnter={() => setShowHover(true)}
        onMouseLeave={() => setShowHover(false)}
      >
        ?
      </p>
      {showHover && (
        <Tooltip>
          Your dog has stopped earning XP, select this dog in your kennel to
          keep gaining more
        </Tooltip>
      )}
    </>
  );
};
